import { ChainId, ChildTransactionSubtype, ChildTransactionType, GetTransactionParams, request } from "@blobaa/ardor-ts";
import { Item, objectAny } from "../types";
import ItemParser from "./ItemParser";



export default class EntityItemsCollector {
    private readonly url: string;
    private readonly payload: string;


    constructor(url: string, payload: string) {
        this.url = url;
        this.payload = payload;
    }


    public async collect(): Promise<Item[]> {
        let payloadObject: objectAny;

        try {
            payloadObject = JSON.parse(this.payload);
            const authItemParser = new ItemParser();
            let data: Item[] = [];

            if (payloadObject.txHash) {
                data = await this.getData(payloadObject.txHash);
            }

            if (payloadObject.data) {
                data = payloadObject.data;
            }

            if (!authItemParser.checkItems(data)) {
                return Promise.reject();
            }

            return data;
        } catch (e) {
            return Promise.reject();
        }
    }

    private getData = async (txHash: string): Promise<Item[]> => {
        const params: GetTransactionParams = {
            chain: ChainId.IGNIS, 
            fullHash: txHash
        }
        const transaction = await request.getTransaction(this.url, params);
        
        if (transaction.type !== ChildTransactionType.DATA && 
            transaction.subtype !== ChildTransactionSubtype.DATA__TAGGED_DATA_UPLOAD) {
            return Promise.reject();
        }

        return JSON.parse(transaction.attachment.data).data;
    }
}