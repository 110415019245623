import { ItemNames } from "../types";


export default class ItemParser {

    public checkItems(items: any): boolean {
        if(!Array.isArray(items)) return false;

        for(let i = 0 ; i < items.length ; i++) {
            const item = items[i];

            if(!item.name) return false;
            if(!item.value) return false;

            const index = Object.values(ItemNames).findIndex(itemName => {
                return itemName === item.name;
            });
            if(index === -1) return false;
        }

        return true;
    }


    public checkItemNames(names: string[]): boolean {
        if(!Array.isArray(names)) return false;

        for(let i = 0 ; i < names.length ; i++) {
            const name = names[i];

            if(typeof name !== "string") return false;

            const index = Object.values(ItemNames).findIndex(itemName => {
                return itemName === name;
            });
            if(index === -1) return false;
        }

        return true;
    }
}