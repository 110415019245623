import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { close } from "ionicons/icons";
import React from "react";
import './../style/StyleSheet.css';


interface Props {
    title: string;
    hideCancelButton?: boolean
    onCancel: () => void;
    hideOkButton?: boolean;
}

const ModalLayout: React.FC<Props> = (props) => {
    const handleCancelRequest = (): void => {
        props.onCancel();
    }

    return (
        <IonModal
            isOpen={true}
            onDidDismiss={handleCancelRequest}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        {props.title} 
                    </IonTitle>
                    {!props.hideCancelButton ?
                        <IonButtons slot="end">
                            <IonButton onClick={handleCancelRequest}>
                                <IonIcon slot="icon-only" icon={close} />
                            </IonButton>
                        </IonButtons>
                    : null}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {props.children}
                {!props.hideOkButton ?
                    <div className="modal-list-button">
                        <IonButton onClick={handleCancelRequest} expand="block">
                            Ok
                        </IonButton>
                    </div>
                : null}
            </IonContent>
        </IonModal>
    );
}

export default ModalLayout;