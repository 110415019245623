import { IonContent, IonHeader, IonImg, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import ServerPath from '../../../lib/ServerPath';
import { SettingsRoutes } from '../../../routes';


const SettingsScreen: React.FC = () => {
    const history = useHistory();
    
    const handleClaimSettingsSelect = (): void => {
        history.push(SettingsRoutes.CLAIM);
    }
    
    const handleVerificationSettingsSelect = (): void => {
        history.push(SettingsRoutes.VERIFICATION);
    }

    const handleBlockchainSettingsSelect = (): void => {
        history.push(SettingsRoutes.BLOCKCHAIN);
    }

    const handleMiscellaneousSettingsSelect = (): void => {
        history.push(SettingsRoutes.MISC);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Settings
                    </IonTitle>
                    <IonImg
                        className="toolbar-img"
                        slot="end"
                        style={{height: "30px"}}
                        src={ServerPath.get("/assets/common/blobaa-ico.png")}/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem button onClick={handleClaimSettingsSelect}>
                        <IonLabel>
                            <h2>
                                Claim
                            </h2>
                            <p>
                                Claim Data, Attestation Path...
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem button onClick={handleVerificationSettingsSelect}>
                        <IonLabel>
                            <h2>
                                Verification
                            </h2>
                            <p>
                                Trusted Root Account, Trusted Context...
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem button onClick={handleBlockchainSettingsSelect}>
                        <IonLabel>
                            <h2>
                                Blockchain
                            </h2>
                            <p>
                                Passphrase, Node Urls...
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem button onClick={handleMiscellaneousSettingsSelect}>
                        <IonLabel>
                            <h2>
                                Miscellaneous
                            </h2>
                            <p>
                                App Password, Email...
                            </p>
                        </IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default SettingsScreen;
