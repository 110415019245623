import { Error, SignedData } from "@blobaa/attestation-protocol-ts";
import { IonBackButton, IonButtons, IonContent, IonHeader, IonImg, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router";
import ProcessFinishedView from "../../components/ProcessFinishedView";
import WaitingModal from "../../components/WaitingModal";
import config from "../../dev/config";
import PlatformChecker from "../../lib/PlatformChecker";
import ServerPath from "../../lib/ServerPath";
import SignedDataParser from "../../lib/SignedDataParser";
import TrustChainParser from "../../lib/TrustChainParser";
import { TrustChainData, WebAuth } from "../../types";
import './../../style/StyleSheet.css';
import VerificationSuccessView from "./VerificationSuccessView";


const screenTitle = "Web Authentication";

const verificationSuccessSubtitle = "Domain Verification";

const verificationErrorTitle = "Verification Failed";
const IconErrorPath = ServerPath.get("/assets/common/error.svg");

const OK = "ok";


const WebAuthentication: React.FC = () => {
    let history = useHistory();
    const trustChainData = useRef({} as TrustChainData);
    const authRequest = useRef({} as WebAuth);
    const verificationError = useRef("");
    const fragment = useRef(<div/>);
    const [updateFragment, setUpdateFragment] = useState(true);


    useEffect(() => {
        if (!updateFragment) return;
        setUpdateFragment(false);


        if (verificationError.current === "") { // called on didMount
            parseInputData(history.location.state as string)
            .then(value => {
                authRequest.current = value.request;
                trustChainData.current = value.trustChainData;
                verificationError.current = OK;
            })
            .catch(error => {
                verificationError.current = error
            }).finally(() => {
                setUpdateFragment(true);
            }); 
            fragment.current = <WaitingModal/>;
            return;
        }


        if (verificationError.current !== OK) {
            fragment.current = <ProcessFinishedView 
                title={verificationErrorTitle}
                subtitle={verificationError.current}
                iconPath={IconErrorPath}
                message={createVerificationErrorMessage()}/>;
            return;
        }


        // verification succeeded
        fragment.current = <VerificationSuccessView
            subtitle={verificationSuccessSubtitle}
            trustChainData={trustChainData.current}
            authRequest={authRequest.current} />
            
    }, [updateFragment, history.location.state]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{screenTitle}</IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonImg
                        className="toolbar-img"
                        slot="end"
                        style={{height: "30px"}}
                        src={ServerPath.get("/assets/common/blobaa-ico.png")}/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {fragment.current}
           </IonContent>
        </IonPage>
    )
}

const parseInputData = async (data: string): Promise<{request: WebAuth, trustChainData: TrustChainData}> => {
    const signedDataParser = new SignedDataParser(data);
    const signedData = signedDataParser.getObject();
    const request = signedDataParser.getRequest() as WebAuth;


    // let signatureTimeout = config.verification.signatureTimeout;
    let signatureTimeout = -1;

    const trustChainParser = new TrustChainParser(config.verification, config.blockchain.url, signatureTimeout, config.blockchain.isTestnet);
    
    try {
        const trustChainData = await trustChainParser.parse(signedData as SignedData);
        return {request, trustChainData};
    } catch(e) {
        return Promise.reject(JSON.stringify((e as Error).description))
    }
}

const createVerificationErrorMessage = (): string => {
    return "Could not verify " + (PlatformChecker.isWeb() ? "your submitted data" : "the QR Code") + " :(";
}

export default WebAuthentication;
