import { UserData } from "@blobaa/claim-ts";
import { IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import ModalLayout from "../../../components/ModalLayout";
import ItemNameBeautifier from "../../../lib/ItemNameBeautifier";
import './../../../style/StyleSheet.css';


interface Props {
    claimData: UserData;
    onCancel: () => void;
}


const ClaimDataModal: React.FC<Props> = (props) => {

    const handleCancelRequest = (): void => {
        props.onCancel();
    }

    return (
        <ModalLayout
            title={new ItemNameBeautifier(props.claimData.name).beautify()}
            onCancel={handleCancelRequest}>
            <IonList>
                <IonItem>
                    <IonLabel className="ion-text-wrap">
                        <h2>
                            Name:
                        </h2>
                        <p>
                            {props.claimData.name}
                        </p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel className="ion-text-wrap">
                        <h2>
                            Value:
                        </h2>
                        <p>
                            {props.claimData.value}
                        </p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonLabel className="ion-text-wrap">
                        <h2>
                            Nonce:
                        </h2>
                        <p>
                            {props.claimData.nonce}
                        </p>
                    </IonLabel>
                </IonItem>
            </IonList>
        </ModalLayout>
    );
}

export default ClaimDataModal;