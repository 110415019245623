import { IonContent, IonHeader, IonImg, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import config from '../../dev/config';
import PlatformChecker from '../../lib/PlatformChecker';
import ServerPath from '../../lib/ServerPath';
import { UserAuthentication, WebAuthentication } from '../../routes';
import ManualEntryView from './ManualEntryView';
import QRCodeScanView from './QRCodeScanView';
import { StaticRequest, WebAuth, RequestType } from '../../types';
import SignedDataParser from '../../lib/SignedDataParser';
import ProcessFinishedView from '../../components/ProcessFinishedView';


const viewTitle = "Entry Mode";
const inputDataSubtitle = "Manual";
const scanQRSubtitle = "QR Code";
const screenTitle = "Data Entry"

const verificationErrorTitle = "Data Entry Failed";
const verificationErrorSubtitle = "Invalid Data Format";
const verificationErrorMessage = "Could not verify your submitted data format :(";
const IconErrorPath = ServerPath.get("/assets/common/error.svg");


const DataEntryScreen: React.FC = () => {
    const history = useHistory();
    const currentFragment = useRef(<div/>);
    const dataEntryFragment = useRef(<div/>);
    const [isErrorView, setIsErrorView] = useState(false);


    useEffect(() => {
        const handleInputData = (data: string): void => {
            const request = parseEntryData(data);
            if (!request) {
                currentFragment.current = <ProcessFinishedView 
                    title={verificationErrorTitle}
                    subtitle={verificationErrorSubtitle}
                    iconPath={IconErrorPath}
                    message={verificationErrorMessage}/>;
                    setIsErrorView(true);
                return;
            }
            
            switch (request.type) {
                case RequestType.STATIC: {
                    history.push(UserAuthentication.MAIN, data);
                    break;
                }
                case RequestType.WEB: {
                    history.push(WebAuthentication.MAIN, data);
                    break;
                }
            }
        }

        if (PlatformChecker.isWeb() || config.isDev) {
            const params = new URLSearchParams(history.location.state as string);
            dataEntryFragment.current = <ManualEntryView 
                title={viewTitle}
                subtitle={inputDataSubtitle}
                initValue={params.get("request") || ""}
                onSubmit={handleInputData}/>;
        } else {
            dataEntryFragment.current = <QRCodeScanView
                title={viewTitle}
                subtitle={scanQRSubtitle}
                onQRCodeScanned={handleInputData}/>;
        }

        currentFragment.current = dataEntryFragment.current;
    }, [history]);


    useEffect(() => {
        if (isErrorView) {
            setTimeout(() => {
                currentFragment.current = dataEntryFragment.current
                setIsErrorView(false);
            }, 2000);
        }
    }, [isErrorView]);

 
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{screenTitle}</IonTitle>
                    <IonImg
                        className="toolbar-img"
                        slot="end"
                        style={{height: "30px"}}
                        src={ServerPath.get("/assets/common/blobaa-ico.png")}/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {currentFragment.current}
            </IonContent>
        </IonPage>
    );
};


const parseEntryData = (data: string): StaticRequest | WebAuth | undefined => {
    const signedDataParser = new SignedDataParser(data);
    const request = signedDataParser.getRequest();
    return request;
}

export default DataEntryScreen;