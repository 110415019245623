import { IonImg, IonText } from "@ionic/react";
import React from "react";
import './../style/StyleSheet.css';

interface Props {
    message: string;
    title: string;
    subtitle: string;
    iconPath: string
}

const ProcessFinishedView: React.FC<Props> = (props) => {

    return (
        <div className="container">
            <div className="header-title">
                <IonText>
                    <b>{props.title}</b>
                </IonText>
            </div>
            <div className="header-subtitle">
                <IonText>
                    <b>{props.subtitle}</b>
                </IonText>
            </div>
            <div className="state-icon">
                <IonImg src={props.iconPath} />
            </div>
            <div className="content-center-horizontal">
                <IonText>
                    {props.message}
                </IonText>
            </div>
        </div>
    );
}

export default ProcessFinishedView