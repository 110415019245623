import { data, Error, SignDataParams, SignedData } from "@blobaa/attestation-protocol-ts";
import { Claim } from '@blobaa/claim-ts';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonImg, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import axios from "axios";
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router";
import ProcessFinishedView from "../../components/ProcessFinishedView";
import WaitingModal from "../../components/WaitingModal";
import config from "../../dev/config";
import PlatformChecker from "../../lib/PlatformChecker";
import ServerPath from "../../lib/ServerPath";
import SignedDataParser from "../../lib/SignedDataParser";
import TrustChainParser from "../../lib/TrustChainParser";
import { Item, ItemNames, RequestType, StaticRequest, StaticResponse, TrustChainData } from "../../types";
import './../../style/StyleSheet.css';
import VerificationSuccessView from "./VerificationSuccessView";


const screenTitle = "User Authentication";

const verificationSuccessSubtitle = "Data Request";
const authenticationSuccessSubtitle = "Authentication Succeeded"

const verificationErrorTitle = "Verification Failed";
const authenticationErrorTitle = "Authentication Failed";
const authenticationErrorSubtitle = "Request Rejected"

const IconSuccessPath = ServerPath.get("/assets/common/success.svg");
const IconErrorPath = ServerPath.get("/assets/common/error.svg");

const OK = "ok";


const UserAuthenticationScreen: React.FC = () => {
    let history = useHistory();
    const trustChainData = useRef({} as TrustChainData);
    const authRequest = useRef({} as StaticRequest);
    const verificationError = useRef("");
    const authenticationError = useRef("");
    const fragment = useRef(<div/>);
    const [updateFragment, setUpdateFragment] = useState(true);


    useEffect(() => {
        if (!updateFragment) return;
        setUpdateFragment(false);


        if (verificationError.current === "") { // called on didMount
            parseInputData(history.location.state as string)
            .then(value => {
                authRequest.current = value.request;
                trustChainData.current = value.trustChainData;
                verificationError.current = OK;
            })
            .catch(error => {
                verificationError.current = error
            }).finally(() => {
                setUpdateFragment(true);
            }); 
            fragment.current = <WaitingModal/>;
            return;
        }


        if (verificationError.current !== OK) {
            fragment.current = <ProcessFinishedView 
                title={verificationErrorTitle}
                subtitle={verificationError.current}
                iconPath={IconErrorPath}
                message={createVerificationErrorMessage()}/>;
            return;
        }


        // verification succeeded
        if (authenticationError.current === "") { 
            const handleAuthenticationRequest = () => {
                requestAuthentication(authRequest.current)
                .then(value => {
                    authenticationError.current = value;
                })
                .catch(error => {
                    authenticationError.current = error;
                }).finally(() => {
                    setUpdateFragment(true);
                });
            };

            fragment.current = <VerificationSuccessView
                subtitle={verificationSuccessSubtitle}
                trustChainData={trustChainData.current}
                authRequest={authRequest.current}
                password={config.misc.password}
                onSubmit={handleAuthenticationRequest}/>;
            return;
        }


        if (authenticationError.current !== OK) {
            fragment.current = <ProcessFinishedView 
                title={authenticationErrorTitle}
                subtitle={authenticationErrorSubtitle}
                iconPath={IconErrorPath}
                message={createAuthenticationErrorMessage(trustChainData.current)}/>;
            return;
        }


        if (authenticationError.current === OK) {
            fragment.current = <ProcessFinishedView 
                title={authRequest.current.title}
                subtitle={authenticationSuccessSubtitle}
                iconPath={IconSuccessPath}
                message={createSuccessMessage(trustChainData.current)}/>
            return;
        }
              
    }, [updateFragment, history.location.state]);


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{screenTitle}</IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonImg
                        className="toolbar-img"
                        slot="end"
                        style={{height: "30px"}}
                        src={ServerPath.get("/assets/common/blobaa-ico.png")}/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {fragment.current}
           </IonContent>
        </IonPage>
    )
}

const parseInputData = async (data: string): Promise<{request: StaticRequest, trustChainData: TrustChainData}> => {
    const signedDataParser = new SignedDataParser(data);
    const signedData = signedDataParser.getObject();
    const request = signedDataParser.getRequest() as StaticRequest;


    let signatureTimeout = config.verification.signatureTimeout;
    if(request.type === RequestType.STATIC) {
        signatureTimeout = -1;
    }


    const trustChainParser = new TrustChainParser(config.verification, config.blockchain.url, signatureTimeout, config.blockchain.isTestnet);
    
    try {
        const trustChainData = await trustChainParser.parse(signedData as SignedData);
        return {request, trustChainData};
    } catch(e) {
        return Promise.reject(JSON.stringify((e as Error).description))
    }
}

const requestAuthentication = async(authRequest: StaticRequest): Promise<string> => {
    const staticResponse: StaticResponse = {
        type: RequestType.STATIC
    }


    const claim = new Claim();
    claim.setUserData({ userData: config.claim.claimData });
    const claimObject = claim.createClaim({userDataNames: authRequest.authItemNames || []});
    if(claimObject.userData.length !== 0) {
        staticResponse.authItems = claimObject;
    }


    let reqItems: Item[] = [];
    authRequest.reqItemNames?.forEach(itemName => {
        const item = getItem(itemName);
        if (item) reqItems.push(item);
    })

    if(reqItems.length !== 0) {
        staticResponse.reqItems = reqItems;
    }
    

    const params: SignDataParams = {
        attestationContext: config.claim.context,
        attestationPath: config.claim.attestationPath,
        payload: JSON.stringify(staticResponse),
        passphrase: config.blockchain.passphrase
    }
    const signedClaim = data.signData(params, true);
    
    
    const resp = await axios.post(authRequest.api, qs.stringify(signedClaim));
    return resp.data;
}

const getItem = (itemName: string): Item => {
    const item: Item = {
        name: itemName,
        value: ""
    }

    switch (itemName) {
        case ItemNames.LEGAL_TERMS_AND_CONDITIONS: {
            item.value = "true";
            return item;
        }
        case ItemNames.CONTACT_EMAIL: {
            item.value = config.misc.email;
            return item;
        }
        default: {
            return item;
        }
    }
}

const createSuccessMessage = (trustChainData: TrustChainData): string => {
    let entityName = "";
    trustChainData.entities[0].authItems.forEach(authItem => {
        if(authItem.name === ItemNames.COMPANY_NAME) {
            entityName = authItem.value;
        }
    });
    return "Congratulation! You have successfully submitted your data to " + entityName + " :)"
}

const createVerificationErrorMessage = (): string => {
    return "Could not verify " + (PlatformChecker.isWeb() ? "your submitted data" : "the QR Code") + " :(";
}

const createAuthenticationErrorMessage = (trustChainData: TrustChainData): string => {
    let entityName = "";
    trustChainData.entities[0].authItems.forEach(authItem => {
        if(authItem.name === ItemNames.COMPANY_NAME) {
            entityName = authItem.value;
        }
    });

    return entityName + " rejected your authentication request :(";
}

export default UserAuthenticationScreen;
