import React, { useState } from "react";
import EntityInfoModal from "../../components/EntityInfoModal";
import { Entity, TrustChainData } from "../../types";
import './../../style/StyleSheet.css';
import VerificationDetailsModal from "./VerificationDetailsModal";


interface Props {
    trustChainData: TrustChainData;
    onCancel: () => void;
}


const TrustChainModals: React.FC<Props> = (props) => {
    const [entity, setEntity] = useState({} as Entity);

    const handleVerificationDetailsCancelRequest = (): void => {
        props.onCancel();
    }

    const handleEntityInfoCancelRequest = (): void => {
        setEntity({} as Entity)
    }

    const handleEntitySelect = (entity: Entity): void => {
        setEntity(entity);
    }

    return (
        <div>
            <VerificationDetailsModal
                onCancel={handleVerificationDetailsCancelRequest}
                onEntitySelect={handleEntitySelect}
                trustChainData={props.trustChainData}/>
            {entity.authItems ? 
                <EntityInfoModal
                    entity={entity}
                    onCancel={handleEntityInfoCancelRequest}/>
            : null}
        </div>
    );
}


export default TrustChainModals;