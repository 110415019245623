import { IonButton, IonText, IonItem, IonLabel } from "@ionic/react";
import React, { useState } from "react";
import { v4 as uuid } from 'uuid';
import PasswordModal from "../../components/PasswordModal";
import WaitingModal from "../../components/WaitingModal";
import ItemNameBeautifier from "../../lib/ItemNameBeautifier";
import PlatformChecker from "../../lib/PlatformChecker";
import { Entity, ItemNames, StaticRequest, TrustChainData } from "../../types";
import './../../style/StyleSheet.css';
import RequestEntityModal from "./RequestEntityModal";
import TrustChainModals from "./TrustChainModals";
import ItemValueExtractor from "../../lib/ItemValueExtractor";
import config from '../../dev/config'


interface Props {
    subtitle: string;
    trustChainData: TrustChainData;
    authRequest: StaticRequest;
    onSubmit: () => void;
    password: string;
}

const VerificationSuccessView: React.FC<Props> = (props) => {
    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [showTrustChainModals, setShowTrustChainModals] = useState(false);
    const [showRequestEntityModal, setShowRequestEntityModal] = useState(false);
    const [showPinModal, setShowPinModal] = useState(false);


    const handleConfirmRequest = (): void => {
        setShowPinModal(true);
    }

    const handleDetailsView = (): void => {
        setShowTrustChainModals(true);
    }

    const handleEntityView = (): void => {
        setShowRequestEntityModal(true);
    }

    const handlePasswordOk = (): void => {
        setShowWaitingModal(true);
        props.onSubmit();
    }

    return (
        <div className="container">
            <div className="header-title">
                <IonText>
                    <b>{props.authRequest.title}</b>
                </IonText>
            </div>
            <div className="header-subtitle">
                <IonText>
                    <b>{props.subtitle}</b>
                </IonText>
            </div>
            <IonText>
                <p>
                    <span>{PlatformChecker.isWeb() ? "Submitted data" : "QR Code"} could be verified successfully <span className="clickable-text"><IonText color="primary" onClick={handleDetailsView}>(details)</IonText></span>.
                    </span> {getEntityType(props.trustChainData.entities[0])} <span className="clickable-text"><IonText color="primary" onClick={handleEntityView}>{ new ItemValueExtractor([ItemNames.COMPANY_NAME], props.trustChainData.entities[0].authItems).extract()}</IonText></span> requests the following authentication data:
                </p>
                {createAuthItemList(props.authRequest.authItemNames || [])}   
                <br/>
                <p>
                    In addition to the data below:
                </p>
                {createReqItemList(props.authRequest.reqItemNames || [])}
                <br/>
                <p>
                    Further information are available under:
                </p>
                <ul>
                    {props.authRequest.urls?.map((value) => {
                        return (
                            <li key={uuid()}>
                                <a 
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={value.url}>
                                    {value.label}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </IonText>
            <div className="verification-success-confirm-button">
                <IonButton onClick={handleConfirmRequest} expand="block" size="large">
                    Confirm
                </IonButton>
            </div>
            {showWaitingModal ? <WaitingModal/> 
            : null}
            {showTrustChainModals ? <TrustChainModals
                trustChainData={props.trustChainData} 
                onCancel={() => setShowTrustChainModals(false)}/> 
            : null} 
            {showRequestEntityModal ? <RequestEntityModal 
                entity={props.trustChainData.entities[0]}
                onCancel={() => setShowRequestEntityModal(false)}/>
            : null}
            {showPinModal ? <PasswordModal 
                password={props.password}
                onDidDismiss={() => setShowPinModal(false)}
                onPasswordOk={handlePasswordOk}/>
            : null}
        </div>
    );
}

const getEntityType = (entity: Entity): string => {
    let entityType = "";
    entity.authItems.forEach(item => {
        if(item.name === ItemNames.COMPANY_NAME) {
            entityType = "Company";
        }
    })
    return entityType;
}

const createAuthItemList = (authItemNames: string[]): JSX.Element => {
    return (
        <div>
            {authItemNames?.map((name) => {
                return (
                    <IonItem key={uuid()}>
                        <IonLabel>
                            <h2>{(new ItemNameBeautifier(name)).beautify()}:</h2>
                            <p>{new ItemValueExtractor([ name ], config.claim.claimData).extract()}</p>
                        </IonLabel>
                    </IonItem>
                );
            })}
        </div>
    );
}

const createReqItemList = (reqItemNames: string[]): JSX.Element => {
    return (
        <div>
            {reqItemNames?.map((name) => {
                return (
                   <IonItem key={uuid()}>
                        <IonLabel>
                            <h2>{(new ItemNameBeautifier(name)).beautify()}:</h2>
                            <p>{setReqItemValue(name)}</p>
                        </IonLabel>
                    </IonItem>
                );
            })}
        </div>
    );
}

const setReqItemValue = (name: string): string => {
    let value = new ItemValueExtractor([ name ], config.claim.claimData).extract();

    if(!value && name === ItemNames.LEGAL_TERMS_AND_CONDITIONS) {
        value = "accept";
    }
    if(!value && name === ItemNames.CONTACT_EMAIL) {
        value = config.misc.email;
    }
    return value;
}


export default VerificationSuccessView