import { IonButton, IonInput, IonItem, IonText } from "@ionic/react";
import React, { useRef, useState } from "react";
import './../style/StyleSheet.css';
import ModalLayout from "./ModalLayout";


interface Props {
    onPasswordOk: () => void;
    onDidDismiss: () => void;
    password: string;
}


const PasswordModal: React.FC<Props> = (props) => {
    const password = useRef("");
    const [error, setError] = useState("");
    

    const handleOKRequest = (): void => {
        if (password.current !== props.password) {
            setError("Your password is wrong");
        } else {
            props.onPasswordOk();
        }
    }

    const handleValueChange = (event: CustomEvent<any>): void => {
        password.current = event.detail.value;
    }

    const handleOnInputClick = (): void => {
        setError("");
        password.current = "";
    }

    const setInputValue = (): string => {
        return error ? error : password.current;
    }

    const setInputType = (): "text" | "password" => {
        return error ? "text" : "password";
    }


    return (
        <ModalLayout
            title="Password Request"
            onCancel={props.onDidDismiss}
            hideCancelButton
            hideOkButton>
            <p className="password-modal-text">
                <IonText>
                    Please enter your application password
                </IonText>
            </p>
            <IonItem lines="inset" className={error ? "password-modal-error-item" : ""}>
                <IonInput
                    type={setInputType()}
                    placeholder="Enter your password"
                    value={setInputValue()}
                    onClick={handleOnInputClick}
                    onIonChange={handleValueChange}
                    color={error ? "danger" : ""}/>
            </IonItem>
            <div className="password-modal-buttons">
                <span className="password-modal-button">
                    <IonButton onClick={props.onDidDismiss} color="medium">
                        Cancel
                    </IonButton>
                </span>
                <span className="password-modal-button">
                    <IonButton onClick={handleOKRequest}>
                        Ok
                    </IonButton>
                </span>
            </div>
        </ModalLayout>
    );
}

export default PasswordModal;