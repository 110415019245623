import { ItemNames } from "../types";


export default class ItemNameBeautifier {
    private readonly itemName: string;

    constructor(itemName: string) {
        this.itemName = itemName;
    }

    

    public beautify(): string {
        switch (this.itemName) {
            case ItemNames.ADDRESS_CITY: {
                return "City Name"
            }
            case ItemNames.ADDRESS_COUNTRY: {
                return "Country Name";
            }
            case ItemNames.ADDRESS_NUMBER: {
                return "Street Number";
            }
            case ItemNames.ADDRESS_STREET: {
                return "Street Name";
            }
            case ItemNames.ADDRESS_ZIP: {
                return "ZIP";
            }
            case ItemNames.COMPANY_LEGAL_FORM: {
                return "Company Legal Form";
            }
            case ItemNames.COMPANY_MANAGING_DIRECTOR: {
                return "Managing Director";
            }
            case ItemNames.COMPANY_NAME: {
                return "Company Name";
            }
            case ItemNames.COMMERCIAL_REGISTER_NAME: {
                return "Commercial Register Name";
            }
            case ItemNames.COMMERCIAL_REGISTER_NUMBER: {
                return "Commercial Register Number";
            }
            case ItemNames.CONTACT_EMAIL: {
                return "Email Address";
            }
            case ItemNames.ENTITY_NAME: {
                return "Name";
            }
            case ItemNames.LEGAL_TERMS_AND_CONDITIONS: {
                return "Terms and Conditions";
            }
            case ItemNames.PERSON_FIRST_NAME: {
                return "First Name";
            }
            case ItemNames.PERSON_SURNAME: {
                return "Last Name";
            }
            case ItemNames.PERSON_BIRTHDAY: {
                return "Birthday";
            }
            case ItemNames.TAX_VATID: {
                return "VAT Id";
            }
            default: {
                return "";
            }
        }
    }
}