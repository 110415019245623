import { data, EntityCheckParams, Error, SignedData, SignedDataCheckParams, VerifySignedDataParams } from '@blobaa/attestation-protocol-ts';
import { Entity, TrustChainData } from '../types';
import EntityItemsCollector from './EntityItemsCollector';


enum ParsingError {
    OK,
    TIME,
    CONTEXT
}

export default class TrustChainParser {
    private readonly trustedRoot: string;
    private readonly trustedContext: string;
    private readonly url: string;
    private readonly isTestnet: boolean;
    private readonly timeWindow: number;

    private signatureTime = 0;
    private parsingError = ParsingError.OK;
    private entityParams: EntityCheckParams[] = [];
    

    constructor(trustedValues: {rootAccount: string, context: string}, url: string, timeWindowInSec: number, isTestnet: boolean) {
        this.trustedRoot = trustedValues.rootAccount;
        this.trustedContext = trustedValues.context;
        this.url = url;
        this.isTestnet = isTestnet;
        this.timeWindow = timeWindowInSec * 1000;
    }


    public async parse(signedData: SignedData): Promise<TrustChainData> {
        try {
            const params: VerifySignedDataParams = {
                trustedRootAccount: this.trustedRoot,
                signedData: signedData,
                signedDataCheckCallback: this.checkData,
                entityCheckCallback: this.checkEntity
            };

            await data.verifySignedData(this.url, params, this.isTestnet);
        } catch(e) {
            const error: Error = {code: -1, description: ""};
            switch(this.parsingError) {
                case ParsingError.OK: {
                    return Promise.reject(e);
                }
                case ParsingError.CONTEXT: {
                    error.description = "Unknown Context";
                    return Promise.reject(error);
                }
                case ParsingError.TIME: {
                    error.description = "Signature Timeout";
                    return Promise.reject(error);
                }
                default: {
                    return Promise.reject(e);
                }
            }
        }

        try {
            const entities: Entity[] = [];
            for (let i = 0 ; i < this.entityParams.length ; i++) {
                const entityParams = this.entityParams[i];
                const authItems = await new EntityItemsCollector(this.url, entityParams.payload).collect();
                entities.push({params: entityParams, authItems})
            }

            return {entities, signatureTime: this.signatureTime};
        } catch(e) {
            const error: Error = {code: -1, description: "Entity Identification Error"};
            return Promise.reject(error);
        }
    }

    private checkData = (params: SignedDataCheckParams): boolean => {
        this.signatureTime = params.signatureTime;

        if(!this.checkContext(params.signedData.attestationContext)) {
            this.parsingError = ParsingError.CONTEXT;
            return false;
        }

        if(!this.checkSignatureTime(this.signatureTime)) {
            this.parsingError = ParsingError.TIME;
            return false;
        }

        return true;
    }

    private checkContext = (context: string): boolean => {
        return context === this.trustedContext
    }

    private checkSignatureTime = (signatureTime: number): boolean => {
        if (this.timeWindow <= 0) return true;
        const currentTime = (new Date()).getTime();
        return signatureTime + this.timeWindow > currentTime && 
               signatureTime - this.timeWindow < currentTime;
    }

    private checkEntity = (params: EntityCheckParams): boolean  => {
        this.entityParams.push(params);
        return true;
    }
}