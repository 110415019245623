import { IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import React, { useRef } from "react";
import { v4 as uuid } from "uuid";
import ModalLayout from "../../components/ModalLayout";
import ItemValueExtractor from "../../lib/ItemValueExtractor";
import { Entity, ItemNames, TrustChainData } from "../../types";


interface Props {
    trustChainData: TrustChainData;
    onCancel: () => void;
    onEntitySelect: (entity: Entity) => void;
}


const VerificationDetailsModal: React.FC<Props> = (props) => {
    const entityItems = useRef({} as JSX.Element[]);

    if(!entityItems.current[0]) {
        entityItems.current = createEntityItems(props.trustChainData.entities, props.onEntitySelect);
    }

    return (
        <ModalLayout
            title="Verification Details"
            onCancel={() => props.onCancel()}>
            <IonList>
                <IonItem>
                    <IonLabel>
                        <h2>
                            Signature Time:
                        </h2>
                        <p>
                            {new Date(props.trustChainData.signatureTime).toUTCString()}
                        </p>
                    </IonLabel>
                </IonItem>
                {entityItems.current}
            </IonList>
        </ModalLayout>
    );
}

const createEntityItems = (entities: Entity[], onEntitySelect: (entity: Entity) => void): JSX.Element[] => {
    return entities.map((entity, index) => {
        return (
            <IonItem button key={uuid()} onClick={() => {onEntitySelect(entity)}}>
                <IonLabel>
                    <h2>
                        {getEntityLabel(index, entities.length) + ":"}
                    </h2>
                    <p>
                        <IonText color="primary">
                            {new ItemValueExtractor([ ItemNames.COMPANY_NAME, ItemNames.ENTITY_NAME ], entity.authItems).extract()}
                        </IonText>
                    </p>
                </IonLabel>
            </IonItem>
        );
    });
}

const getEntityLabel = (index: number, length: number): string => {
    if(index === 0) return "Issuer";
    if(index === length - 1) return "Root";
    return "Intermediate";
}

export default VerificationDetailsModal;