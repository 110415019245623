import { IonText, IonImg, IonGrid, IonCol, IonRow } from "@ionic/react";
import React, { useState } from "react";
import ItemValueExtractor from "../../lib/ItemValueExtractor";
import PlatformChecker from "../../lib/PlatformChecker";
import { Entity, ItemNames, TrustChainData, WebAuth } from "../../types";
import './../../style/StyleSheet.css';
import RequestEntityModal from "./RequestEntityModal";
import TrustChainModals from "./TrustChainModals";
import ServerPath from "../../lib/ServerPath";


interface Props {
    subtitle: string;
    trustChainData: TrustChainData;
    authRequest: WebAuth;
}

const VerificationSuccessView: React.FC<Props> = (props) => {
    const [showTrustChainModals, setShowTrustChainModals] = useState(false);
    const [showRequestEntityModal, setShowRequestEntityModal] = useState(false);


    const handleDetailsView = (): void => {
        setShowTrustChainModals(true);
    }

    const handleEntityView = (): void => {
        setShowRequestEntityModal(true);
    }


    return (
        <div className="container">
            <div className="header-title">
                <IonText>
                    <b>{props.authRequest.title}</b>
                </IonText>
            </div>
            <div className="header-subtitle">
                <IonText>
                    <b>{props.subtitle}</b>
                </IonText>
            </div>
            <IonText>
                <p>
                    <span>{PlatformChecker.isWeb() ? "Submitted data" : "QR Code"} could be verified successfully <span className="clickable-text"><IonText color="primary" onClick={handleDetailsView}>(details)</IonText></span>. 
                    </span> {getEntityType(props.trustChainData.entities[0])} <span className="clickable-text"><IonText color="primary" onClick={handleEntityView}>{ new ItemValueExtractor([ItemNames.ENTITY_NAME], props.trustChainData.entities[0].authItems).extract()}</IonText></span> uses the following domain:
                </p>
                <h2 style={{margin: "35px 0", textAlign: "center"}}>{props.authRequest.domain}</h2>
                <p>
                    Please compare the website domain of the scanned QR Code with the one above. <br/>
                </p>
                <IonGrid style={{paddingLeft: "0", marginTop: "2rem"}}>
                    <IonRow>
                        <IonCol size="2" style={{paddingLeft: "0"}}>
                            <IonImg src={ServerPath.get("/assets/common/attention.svg")} style={{width: "3rem"}} />
                        </IonCol>
                        <IonCol size="10" style={{margin: "auto"}}>
                            <IonText><b>Caution:</b> If they do not match, you are visiting a fake website.</IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonText>
            {showTrustChainModals ? <TrustChainModals
                trustChainData={props.trustChainData} 
                onCancel={() => setShowTrustChainModals(false)}/> 
            : null} 
            {showRequestEntityModal ? <RequestEntityModal 
                entity={props.trustChainData.entities[0]}
                onCancel={() => setShowRequestEntityModal(false)}/>
            : null}
        </div>
    );
}

const getEntityType = (entity: Entity): string => {
    let entityType = "";
    entity.authItems.forEach(item => {
        if(item.name === ItemNames.COMPANY_NAME) {
            entityType = "Company";
        } else if(item.name === ItemNames.ENTITY_NAME) {
            entityType = "Entity";
        }
    })
    return entityType;
}



export default VerificationSuccessView