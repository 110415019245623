import { IonButton, IonInput, IonText, IonItem } from "@ionic/react";
import React from "react";
import './../../style/StyleSheet.css';


interface Props {
    onSubmit: (data: string) => void;
    title: string;
    subtitle: string;
    initValue?: string;
}


const ManualEntryView: React.FC<Props> = (props) => {
    let inputData = props.initValue || "";
    
    const handleDataInput = (event: CustomEvent<any>): void => {
        inputData = event.detail.value;
    }

    const handleSubmitInput = (): void => {
        props.onSubmit(inputData);
    };

    return (
        <div className="container">
            <div className="header-title">
                <IonText>
                    <b>{props.title}</b>
                </IonText>
            </div>
            <div className="header-subtitle">
                <IonText>
                    <b>{props.subtitle}</b>
                </IonText>
            </div>
            <div style={{textAlign: "center"}}>
                <IonText>
                        Please submit the data
                </IonText>
            </div>
            <div className="input-data-submit-data">
                <IonItem>
                    <IonInput 
                        type="text"
                        placeholder="Data"
                        value={inputData}
                        onIonChange={handleDataInput}/>
                </IonItem>
                <IonButton onClick={handleSubmitInput} expand="block" style={{marginTop: "1rem"}}>
                    Submit
                </IonButton>
            </div>
        </div>
    );
}

export default ManualEntryView