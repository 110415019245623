import { IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { v4 as uuid } from "uuid";
import EntityParamsBeautifier from "../lib/EntityParamsBeautifier";
import ItemNameBeautifier from "../lib/ItemNameBeautifier";
import ItemValueExtractor from "../lib/ItemValueExtractor";
import { Entity, ItemNames } from "../types";
import ModalLayout from "./ModalLayout";


interface Props {
    entity: Entity;
    onCancel: () => void;
}


const EntityInfoModal: React.FC<Props> = (props) => {
    const handleCancelRequest = (): void => {
        props.onCancel();
    }


    return (
        <ModalLayout
            title={new ItemValueExtractor([ItemNames.COMPANY_NAME, ItemNames.ENTITY_NAME], props.entity.authItems).extract()}
            onCancel={handleCancelRequest}>
            <IonList>
                <IonItemGroup>
                    <IonItemDivider>
                        <IonLabel color="dark">
                            <h2>
                                <b>Entity Information</b>
                            </h2>
                        </IonLabel>
                    </IonItemDivider>
                    {props.entity.authItems.map((value) => {
                        return (
                            <IonItem key={uuid()}>
                                <IonLabel>
                                    <h2>
                                        {new ItemNameBeautifier(value.name).beautify() + ":"}
                                    </h2>
                                    <p>
                                        {value.value}
                                    </p>
                                </IonLabel>
                            </IonItem>
                        );
                    })}
                    <IonItemDivider>
                        <IonLabel color="dark">
                            <h2>
                                <b>Trust Chain Information</b>
                            </h2>
                        </IonLabel>
                    </IonItemDivider>
                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h2>
                                Account:
                            </h2>
                            <p>
                                {props.entity.params.account}
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h2>
                                Entity Type:
                            </h2>
                            <p>
                                {new EntityParamsBeautifier("entityType", props.entity.params.entityType).beautify()}
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h2>
                                State:
                            </h2>
                            <p>
                                {new EntityParamsBeautifier("state", props.entity.params.state).beautify()}
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h2>
                                Protocol Version:
                            </h2>
                            <p>
                                {new EntityParamsBeautifier("protocolVersion", props.entity.params.protocolVersion).beautify()}
                            </p>
                        </IonLabel>
                    </IonItem>
                </IonItemGroup>
            </IonList>
        </ModalLayout>
    );
}

export default EntityInfoModal;
