import { EntityType, State } from "@blobaa/attestation-protocol-ts";

export default class EntityParamsBeautifier {
    private readonly name: string;
    private readonly value: any;

    constructor(name: string, value: any) {
        this.name = name;
        this.value = value;
    }

    

    public beautify(): string {
        switch (this.name) {
            case "entityType": {
                if (this.value === EntityType.LEAF) return "Leaf";
                if (this.value === EntityType.INTERMEDIATE) return "Intermediate";
                return "Root";
            }
            case "state": {
                if (this.value === State.ACTIVE) return "Active";
                if (this.value === State.DEPRECATED) return "Deprecated";
                return "Inactive";
            }
            case "protocolVersion": {
                if (this.value === "001") return "v1.0.0"
                return "";
            }
            default: {
                return "";
            }
        }
    }
}