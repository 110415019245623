const config = {
    verification: {
        rootAccount: 'ARDOR-47NS-P7AU-HZNN-84PW6',
        context: 'serverAuthentication',
        signatureTimeout: 60,
    },
    claim: {
        claimData: [
            {
                name: 'address:city',
                value: 'Berlin',
                nonce: 'u396aPrgfR1qmq9VtWMr3zD0zbegE1TYYazI49ahMXoBJbc0eoLrZwNOdVNmmBBG'
            },
            {
                name: 'address:country',
                value: 'Germany',
                nonce: 'XZKLk9qg6Z7lSaVP7LIxE262FKNgEzmdJx8v753uKBqhUrquH5en7I4EGRzTQluj'
            },
            {
                name: 'address:number',
                value: '3',
                nonce: '1zlDP5RKLjXo4IcE20qYjXS2skX8iW4Lh0yYz7Aehhklzhh6us4AcFeNNBhchrls'
            },
            {
                name: 'address:street',
                value: 'Berliner Weg',
                nonce: 'TVM01zFdNUon7jySCJ6EMXhdloafFCOH6CJ98hHepZtWCUYbaJg9S2nXqmWx6pdH'
            },
            {
                name: 'address:zip',
                value: '24211',
                nonce: 'rwAPsrN05umYQKah001poqnkyr1BBfCjSUUaqQ9S7RIsKGcbMhYPZgKYGQuw8hiB'
            },
            {
                name: 'person:firstName',
                value: 'Max',
                nonce: 'EPfCgWu8amdtMlYp7qy38MzqLPKY3EC7NPIiPyHL0RKHEgvLkovjHnXpP3ujRg60'
            },
            {
                name: 'person:surName',
                value: 'Mustermann',
                nonce: 'XWDyudnGDu7ZPFvhyxwjTujWBPzDQKGewbUmERFVoQFjelRZwGE4hD4D13Mr9I3B'
            },
            {
                name: 'person:birthDay',
                value: '11.11.1987',
                nonce: 'aMBY39LpefkwidSqXuSPbqE9sfnaijelPhjimluMhh2qwga9YTW0ASMO7EeqOUxW'
            }
        ],
        attestationPath: [
            "ARDOR-3ZKF-US3F-KGL7-GUEZE",
            "ARDOR-47NS-P7AU-HZNN-84PW6"
        ],
        context: "claimAuthentication"
    },
    blockchain: {
        passphrase: "stray explain direction emotion shoot ugly second chest art feast grey society",
        // url: "https://testardor.atz3n.dev", /* currently not usable because of pruning */
        url: "https://testardor.jelurida.com",
        isTestnet: true
    },
    misc: {
        email: 'max.mustermann@gmail.com',
        password: "1234"
    },
    isDev: false
}

export default config;