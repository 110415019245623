import { IonLoading } from '@ionic/react';
import React from 'react';

const WaitingModal: React.FC = () => {
  return (
      <IonLoading
        isOpen={true}
        message={'Please wait...'}
      />
  );
};


export default WaitingModal