import React from "react";
import EntityInfoModal from "./EntityInfoModal";
import ModalLayout from "./ModalLayout";
import { Entity } from "../types";
import ProcessFinishedView from "./ProcessFinishedView";
import './../style/StyleSheet.css';


interface Props {
    entity: Entity;
    onCancel: () => void;
}


const AccountModal: React.FC<Props> = (props) => {

    const handleCancelRequest = (): void => {
        props.onCancel();
    }

    return (
        <div>
            {props.entity.authItems ?
                <EntityInfoModal
                    entity={props.entity}
                    onCancel={handleCancelRequest}/>
                :
                <ModalLayout
                    title=":("
                    onCancel={handleCancelRequest}>
                    <ProcessFinishedView
                        title="Error"
                        subtitle="Couldn't fetch data"
                        message="Couldn't fetch entity information. Is your phone connect to the internet?"
                        iconPath="/assets/common/error.svg"/>
                </ModalLayout>
            }
        </div>
    );
}

export default AccountModal;