import { IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { v4 as uuid } from 'uuid';
import ModalLayout from "../../components/ModalLayout";
import ItemNameBeautifier from "../../lib/ItemNameBeautifier";
import { Entity, ItemNames } from "../../types";
import './../../style/StyleSheet.css';
import ItemValueExtractor from "../../lib/ItemValueExtractor";


interface Props {
    entity: Entity;
    onCancel: () => void;
}

const RequestEntityModal: React.FC<Props> = (props) => {
    const handleCancelRequest = (): void => {
        props.onCancel();
    }

    return (
        <ModalLayout
            title={new ItemValueExtractor([ItemNames.COMPANY_NAME, ItemNames.ENTITY_NAME], props.entity.authItems).extract()}
            onCancel={handleCancelRequest}>
            <IonList>
                {props.entity.authItems.map((value) => {
                    return (
                        <IonItem key={uuid()}>
                            <IonLabel className="ion-text-wrap">
                                <h2>
                                    {new ItemNameBeautifier(value.name).beautify() + ":"}
                                </h2>
                                <p>
                                    {value.value}
                                </p>
                            </IonLabel>
                        </IonItem>
                    );
                })}
            </IonList>
        </ModalLayout>
    );
}

export default RequestEntityModal;