import { account } from '@blobaa/ardor-ts';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonImg, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import config from '../../../dev/config';
import ServerPath from '../../../lib/ServerPath';


const BlockchainSettingsScreen: React.FC = () => {

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Blockchain
                    </IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonImg
                        className="toolbar-img"
                        slot="end"
                        style={{height: "30px"}}
                        src={ServerPath.get("/assets/common/blobaa-ico.png")}/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonLabel>
                            <h2>
                                Node Url:
                            </h2>
                            <p>
                                {config.blockchain.url}
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h2>
                                Net:
                            </h2>
                            <p>
                                {config.blockchain.isTestnet ? "Testnet" : "Mainnet"}
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>
                            <h2>
                                My Account:
                            </h2>
                            <p>
                                {account.convertPassphraseToAccountRs(config.blockchain.passphrase)}
                            </p>
                        </IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className="ion-text-wrap">
                            <h2>
                                My Account Passphrase:
                            </h2>
                            <h3>
                                CAUTION: Only shown because it's a demo App.
                            </h3>
                            <p>
                                {config.blockchain.passphrase}
                            </p>
                        </IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
};



export default BlockchainSettingsScreen;
