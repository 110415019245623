import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner';
import { IonButton, IonIcon, IonText } from "@ionic/react";
import { qrScanner } from 'ionicons/icons';
import React from "react";


interface Props {
    onQRCodeScanned: (data: string) => void;
    title: string;
    subtitle: string;
}

const QRCodeScanView: React.FC<Props> = (props) => {
    const handleScanQrCode = () => {
        const options: BarcodeScannerOptions = {
            disableAnimations: true,
            disableSuccessBeep: true,
            resultDisplayDuration: 0,
            showTorchButton: true,
            prompt: ""
        }

        BarcodeScanner.scan(options)
        .then(barcodeData => {
            props.onQRCodeScanned(barcodeData.text);
        })
        .catch(error => {
            props.onQRCodeScanned("");
        });
    }

    return (
        <div className="container">
            <div className="header-title">
                <IonText>
                    <b>{props.title}</b>
                </IonText>
            </div>
            <div className="header-subtitle">
                <IonText>
                    <b>{props.subtitle}</b>
                </IonText>
            </div>
            <div style={{textAlign: "center"}}>
                <IonText>
                        Please scan an Blobaa supported QR Code
                </IonText>
            </div>
            <div style={{marginTop: "5rem"}}>
                <IonButton size="large" expand="block" onClick={handleScanQrCode}>
                <IonIcon slot="start" icon={qrScanner} />
                    Scan QR Code
                </IonButton>
            </div>
        </div>
    );
}

export default QRCodeScanView;
