import { entity } from '@blobaa/attestation-protocol-ts';
import { UserData } from '@blobaa/claim-ts';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonImg, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import WaitingModal from '../../../components/WaitingModal';
import config from '../../../dev/config';
import EntityItemsCollector from '../../../lib/EntityItemsCollector';
import ItemNameBeautifier from '../../../lib/ItemNameBeautifier';
import ServerPath from '../../../lib/ServerPath';
import { Entity } from '../../../types';
import AccountModal from './../../../components/AccountModal';
import ClaimDataModal from './ClaimDataModal';


const ClaimSettingsScreen: React.FC = () => {
    const [showClaimDataModal, setShowClaimDataModal] = useState(false);
    const [showAttestorModal, setShowAttestorModal] = useState(false);
    const [showWaitingModal, setWaitingModal] = useState(false);
    const claimData = useRef({} as UserData);
    const entity = useRef({} as Entity);
    const claimDataItems = useRef({} as JSX.Element[]);
    const attestationPathItems = useRef({} as JSX.Element[]);


    const handleClaimDataSelect = (claimDatum: UserData): void => {
        claimData.current = claimDatum;
        setShowClaimDataModal(true);
    }

    const handleAttestorSelect = (attestor: string): void => {
        setWaitingModal(true);
        collectEntityInfo(config.blockchain.url, attestor, config.claim.context, getAttestorAccount(config.claim.attestationPath, attestor))
        .then(resp => {
            entity.current = resp;
        })
        .catch(error => {
            entity.current = {} as Entity;
        })
        .finally(() => {
            setShowAttestorModal(true);
            setWaitingModal(false);
        })
    }

    if(!claimDataItems.current[0]) {
        claimDataItems.current = createClaimDataItems(config.claim.claimData, handleClaimDataSelect);
    }

    if(!attestationPathItems.current[0]) {
        attestationPathItems.current = createAttestationPathItems(config.claim.attestationPath, handleAttestorSelect);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Claim
                    </IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonImg
                        className="toolbar-img"
                        slot="end"
                        style={{height: "30px"}}
                        src={ServerPath.get("/assets/common/blobaa-ico.png")}/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItemGroup>
                        <IonItemDivider>
                            <IonLabel color="dark">
                                <h2>
                                    <b>Claim Data</b>
                                </h2>
                            </IonLabel>
                        </IonItemDivider>
                        {claimDataItems.current}
                        <IonItemDivider>
                            <IonLabel color="dark">
                                <h2>
                                    <b>Attestation Path</b>
                                </h2>
                            </IonLabel>
                        </IonItemDivider>
                        {attestationPathItems.current}
                        <IonItemDivider>
                            <IonLabel color="dark">
                                <h2>
                                    <b>Attestation Context</b>
                                </h2>
                            </IonLabel>
                        </IonItemDivider>
                        <IonItem>
                            <IonLabel>
                                <h2>
                                    Context:
                                </h2>
                                <p>
                                    {config.claim.context}
                                </p>
                            </IonLabel>
                        </IonItem>
                    </IonItemGroup>
                </IonList>
            </IonContent>
            {showWaitingModal ? <WaitingModal/>
            : null}
            {showClaimDataModal ? <ClaimDataModal
                claimData={claimData.current}
                onCancel={() => setShowClaimDataModal(false)}/>
            : null}
            {showAttestorModal ? <AccountModal
                entity={entity.current}
                onCancel={() => setShowAttestorModal(false)}/>
            : null}
        </IonPage>
    );
};

const getAttestorAccount = (attestationPath: string[], account: string): string => {
    let attestor = "";
    for (let i = 0 ; i < attestationPath.length ; i++) {
        if (attestationPath[i] === account) {
            if (i === attestationPath.length - 1) attestor = attestationPath[i];
            else attestor = attestationPath[i + 1];
            break;
        }
    }
    return attestor;
}

const collectEntityInfo = async (url: string, account: string, attestationContext: string, attestor: string): Promise<Entity> => {
    const entityResponse = await entity.getEntity(url, { account, attestationContext, attestor });
    const items = await new EntityItemsCollector(url, entityResponse.payload).collect();
    return {authItems: items, params: entityResponse};
}

const createClaimDataItems = (claimData: UserData[], handleClaimDataSelect: (claimDatum: UserData) => void): JSX.Element[] => {
    return claimData.map((claimDatum) => {
        return (
            <IonItem button key={uuid()} onClick={() => handleClaimDataSelect(claimDatum)}>
                <IonLabel>
                    <h2>
                        {new ItemNameBeautifier(claimDatum.name).beautify() + ":"}
                    </h2>
                    <p>
                        <IonText color="primary">
                            {claimDatum.value}
                        </IonText>
                    </p>
                </IonLabel>
            </IonItem>
        );
    });
}

const createAttestationPathItems = (attestationPath: string[], handleAttestorSelect: (attestor: string) => void): JSX.Element[] => {
    return attestationPath.map((attestor) => {
        return (
            <IonItem button key={uuid()} onClick={() => handleAttestorSelect(attestor)}>
                <IonLabel>
                    <h2>
                        Account:
                    </h2>
                    <p>
                        <IonText color="primary">
                            {attestor}
                        </IonText>
                    </p>
                </IonLabel>
            </IonItem>
        );
    });
}

export default ClaimSettingsScreen;
