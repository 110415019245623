import React, { CSSProperties } from "react";
import "./../style/StyleSheet.css";
import PlatformChecker from "../lib/PlatformChecker";
import ServerPath from "../lib/ServerPath";

interface Props {
    children: JSX.Element;
}

const PhoneMocker: React.FC<Props> = (props) => {
    return (
        <div>
            {PlatformChecker.isDesktopWeb() ?
                <div style={{overflowY: "scroll", height:"100vh"}}>
                    {createHeader()}
                    <div style={{minHeight: "85vh"}}>
                        <div style={phoneContainerStyle}>
                            {props.children}
                        </div>
                    </div>
                    {createFooter()}
                </div>
            :
            <div>
                {props.children}
             </div>
            }
        </div>
    )
}

const phoneContainerStyle: CSSProperties = {
    position: "relative", 
    width: "410px",
    height:"731px",
    border: "1px solid #50505088",
    boxShadow: "0px 0px 4px 0px #50505088",
    marginTop: "50px",
    marginLeft: "calc(50% - 210px)"
}


const createHeader = (): JSX.Element => {
    return (
        <div style={headerStyle}>
            <div style={headerContainerStyle}>
                <span style={headerIconStyle}><a href="https://www.blobaa.dev"><img style={{height: "40px"}} src={ServerPath.get("/assets/common/blobaa-ico-light.svg")} alt="Blobaa"/></a></span>
                <span style={headerTitleStyle}>Demo Web App</span>
                <span style={headerDummyElementStyle}></span>
            </div>
        </div>
    );
}

const headerStyle: CSSProperties = {
    padding: "0 30px",
    backgroundColor: "black",
    height: "66px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 0px 5px 2px #50505088"
}

const headerContainerStyle: CSSProperties = {
    display: "flex",
    maxWidth: "1140px",
    width: "100%",
    justifyContent: "space-between"
}

const headerIconStyle: CSSProperties = {
    width: "100px",
    margin: "auto 0"
}

const headerTitleStyle: CSSProperties = {
    color: "white", 
    width: "200px", 
    textAlign: "center", 
    margin: "auto",
    fontSize: "1.5rem",
    fontWeight: "bold"
}

const headerDummyElementStyle: CSSProperties = {
    color: "white", 
    width: "100px"
}


const createFooter = (): JSX.Element => {
    return (
        <div style={footerStyle}>
            <div style={footerContainerStyle}>
                <span style={footerCopyrightStyle}>© 2020 <a style={{textDecoration: "none"}} href="https://www.linkedin.com/in/attila-aldemir/">Attila Aldemir</a>. All rights reserved.</span>
                <span style={footerBlobaaStyle}>part of <a style={{marginLeft: "3px"}} href="https://www.blobaa.dev"><img src="/assets/common/blobaa.svg" height="22rem" alt=""/></a></span>
                <span style={footerGHPagesStyle}>hosted with <a style={{marginLeft: "2px"}} href="https://pages.github.com"><img src="/assets/common/github-pages.png" height="20rem" alt=""/></a></span>
                <span style={footerIonicStyle}>created with <a style={{marginLeft: "3px"}} href="https://ionicframework.com"><img src="/assets/common/ionic.svg" height="20rem" alt=""/></a></span>
            </div>
        </div>
    );
}

const footerStyle: CSSProperties = {
    padding: "4rem 3rem",
    height: "66px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #00000029",
    marginTop: "3rem"
}

const footerContainerStyle: CSSProperties = {
    display: "flex",
    maxWidth: "1140px",
    width: "100%",
    justifyContent: "space-between",
    color: "#505050"
}

const footerCopyrightStyle: CSSProperties = {
    textDecoration: "none",
    margin: "auto",
    fontSize: "small"
}

const footerBlobaaStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    margin: "auto",
    fontSize: "small"
}

const footerGHPagesStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    padding: "auto",
    margin: "auto",
    fontSize: "small"
}

const footerIonicStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    padding: "auto",
    margin: "auto",
    fontSize: "small"
}


export default PhoneMocker;