import ServerPath from "./lib/ServerPath";

export class UserAuthentication {
    public static readonly MAIN = ServerPath.get("/user-authentication");
}

export class WebAuthentication {
    public static readonly MAIN = ServerPath.get("/web-authentication");
}

export class DataEntryRoutes {
    public static readonly DATA_ENTRY = ServerPath.get("/data-entry");
}

export class SettingsRoutes {
    public static readonly SETTINGS = ServerPath.get("/settings");
    public static readonly CLAIM = SettingsRoutes.SETTINGS + "/claim";
    public static readonly VERIFICATION = SettingsRoutes.SETTINGS + "/verification";
    public static readonly BLOCKCHAIN = SettingsRoutes.SETTINGS + "/blockchain";
    public static readonly MISC = SettingsRoutes.SETTINGS + "/miscellaneous";
}
