import { SignedData } from "@blobaa/attestation-protocol-ts";
import { objectAny, RequestType, StaticRequest, WebAuth } from "../types";
import ItemParser from "./ItemParser";


export default class SignedDataParser {
    private readonly inputData: string;


    constructor(inputData: string) {
        this.inputData = inputData;
    }


    public getObject(): SignedData | undefined {
        let object: objectAny;
        try {
            object = JSON.parse(this.inputData);
        } catch(e) {
            return undefined;
        }
        
        const signedDataObject = object as SignedData;
        if (!signedDataObject.attestationContext) return undefined;
        if (!signedDataObject.attestationPath) return undefined;
        if (!signedDataObject.creatorAccount) return undefined;
        if (!signedDataObject.payload) return undefined;
        if (!signedDataObject.signature) return undefined;

        return signedDataObject;
    }


    public getRequest(): StaticRequest | WebAuth | undefined {
        const signedData = this.getObject();
        if (!signedData) return undefined;
        
        let object: objectAny;
        try {
            object = JSON.parse(signedData.payload);
        } catch(e) {
            return undefined;
        }

        if (object.type === RequestType.STATIC) {
            return this.getStaticRequest(object);
        }

        if (object.type === RequestType.WEB) {
            return this.getWebAuth(object);
        }

        return undefined;
    }

    private getStaticRequest(object: objectAny): StaticRequest | undefined {
        const staticRequest = object as StaticRequest;
        if (!staticRequest.title) return undefined;
        if (!staticRequest.api) return undefined;

        if (staticRequest.authItemNames) {
            const itemParser = new ItemParser();
            if (!itemParser.checkItemNames(staticRequest.authItemNames)) {
                return undefined;
            }
        }

        if (staticRequest.reqItemNames) {
            const itemParser = new ItemParser();
            if (!itemParser.checkItemNames(staticRequest.reqItemNames)) {
                return undefined;
            }
        }

        if(staticRequest.urls) {
            let isUrlType = true;
            staticRequest.urls.forEach( url => {
                if(!url.label || !url.url) {
                    isUrlType = false;
                }
            });
            if (!isUrlType) return undefined;
        }

        return staticRequest;
    }

    private getWebAuth(object: objectAny): WebAuth | undefined {
        const webAuth = object as WebAuth;
        if (!webAuth.title) return undefined;
        if (!webAuth.domain) return undefined;
        return webAuth;
    }
}