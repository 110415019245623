import React, { useRef } from "react";
import { useHistory } from "react-router";
import { DataEntryRoutes } from "../routes";


const ProxyComp: React.FC = () => {
    const redirected = useRef(false);
    const history = useHistory();
    
    if(!redirected.current){
        redirected.current = true;
        history.push(DataEntryRoutes.DATA_ENTRY, history.location.search);
    }
    return <div/>;
}


export default ProxyComp;