import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { code, qrScanner, settings } from 'ionicons/icons';
import React from 'react';
import { Route } from 'react-router-dom';
import ProxyComp from './components/ProxyComp';
import PlatformChecker from './lib/PlatformChecker';
import ServerPath from './lib/ServerPath';
import { DataEntryRoutes, SettingsRoutes, UserAuthentication, WebAuthentication } from './routes';
import DataEntryScreen from './screens/dataEntry/DataEntryScreen';
import BlockchainSettingsScreen from './screens/settings/BlockchainSettingsScreen/BlockchainSettingsScreen';
import ClaimSettingsScreen from './screens/settings/ClaimSettingsScreen/ClaimSettingsScreen';
import MiscSettingsScreen from './screens/settings/MiscSettingsScreen/MiscSettingsScreen';
import SettingsScreen from './screens/settings/SettingsScreen/SettingsScreen';
import VerificationSettingsScreen from './screens/settings/VerificationSettingsScreen/VerificationSettingsScreen';
import UserAuthenticationScreen from './screens/userAuthentication/UserAuthenticationScreen';
import WebAuthenticationScreen from './screens/webAuthentication/WebAuthenticationScreen';
/* Theme variables */
import './theme/variables.css';


const App: React.FC = () => {
    return (
        <IonApp>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Route path={SettingsRoutes.SETTINGS} component={SettingsScreen} exact={true}/>
                        <Route path={SettingsRoutes.CLAIM} component={ClaimSettingsScreen} exact={true}/>
                        <Route path={SettingsRoutes.VERIFICATION} component={VerificationSettingsScreen} exact={true}/>
                        <Route path={SettingsRoutes.BLOCKCHAIN} component={BlockchainSettingsScreen} exact={true}/>
                        <Route path={SettingsRoutes.MISC} component={MiscSettingsScreen} exact={true}/>
                        <Route path={DataEntryRoutes.DATA_ENTRY} component={DataEntryScreen} exact={true}/>
                        <Route path={UserAuthentication.MAIN} component={UserAuthenticationScreen} exact={true}/>
                        <Route path={WebAuthentication.MAIN} component={WebAuthenticationScreen} exact={true}/>
                        <Route path={ServerPath.get("/")} component={ProxyComp} exact={true}/>
                    </IonRouterOutlet>
                    <IonTabBar slot="bottom">
                        <IonTabButton tab="settings" href={SettingsRoutes.SETTINGS}>
                            <IonIcon icon={settings}/>
                            <IonLabel>
                                Settings
                            </IonLabel>
                        </IonTabButton>
                        { PlatformChecker.isWeb() ? 
                            <IonTabButton tab="dataEntry" href={DataEntryRoutes.DATA_ENTRY}>
                                <IonIcon icon={code}/>
                                <IonLabel>
                                    Data Entry
                                </IonLabel>
                            </IonTabButton>
                        :
                            <IonTabButton tab="dataEntry" href={DataEntryRoutes.DATA_ENTRY}>
                                <IonIcon icon={qrScanner}/>
                                <IonLabel>
                                    Scan QR
                                </IonLabel>
                            </IonTabButton>
                        }
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
