import { isPlatform } from "@ionic/react";


export default class PlatformChecker {

    public static isWeb(): boolean {
        return isPlatform('desktop') || isPlatform("mobileweb");
    }

    public static isDesktopWeb(): boolean {
        return isPlatform('desktop') && !isPlatform("mobileweb");
    }
}