import { Item } from "../types";

export default class ItemValueExtractor {
    private readonly name: string[];
    private readonly items: Item[];


    constructor(name: string[], items: Item[]) {
        this.name = name;
        this.items = items;
    }
    

    public extract(): string {
        let value = "";
        this.items.forEach(item => {
            this.name.forEach(name => {
                if(item.name === name) {
                    value = item.value;
                }
            });
        });
        return value;
    }
}