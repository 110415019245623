import { EntityCheckParams } from "@blobaa/attestation-protocol-ts";
import { ClaimObject } from "@blobaa/claim-ts";

/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
export type secureAny = any; // same as any, but used when it's intended and secure

export type objectAny = {[name: string]: secureAny};

export type Item = {
    name: string,
    value: string
}

export type Entity = {
    params: EntityCheckParams,
    authItems: Item[]
}

export type TrustChainData = {
    entities: Entity[];
    signatureTime: number;
} 

export enum RequestType {
    STATIC = "request:static",
    WEB = "auth:web"
}

export interface StaticRequest {
    type: RequestType;
    title: string;
    authItemNames?: string[];
    reqItemNames?: string[];
    urls?: [{label: string, url: string}];
    api: string
}

export interface WebAuth {
    type: RequestType.WEB;
    title: string;
    domain: string;
}

export interface StaticResponse {
    type: RequestType.STATIC;
    authItems?: ClaimObject;
    reqItems?: Item[];
}

export enum ItemNames {
    ADDRESS_CITY = "address:city",
    ADDRESS_COUNTRY = "address:country",
    ADDRESS_NUMBER = "address:number",
    ADDRESS_STREET = "address:street",
    ADDRESS_ZIP = "address:zip",
    COMPANY_LEGAL_FORM = "company:legalForm",
    COMPANY_MANAGING_DIRECTOR = "company:manDir",
    COMPANY_NAME = "company:name",
    COMMERCIAL_REGISTER_NAME = "comReg:name",
    COMMERCIAL_REGISTER_NUMBER = "comReg:number",
    CONTACT_EMAIL = "contact:email",
    ENTITY_NAME = "entity:name",
    LEGAL_TERMS_AND_CONDITIONS = "legal:tac",
    PERSON_FIRST_NAME = "person:firstName",
    PERSON_SURNAME = "person:surName",
    PERSON_BIRTHDAY = "person:birthDay",
    TAX_VATID =  "tax:vatId"
}
