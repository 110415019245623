import { entity } from '@blobaa/attestation-protocol-ts';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonImg, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React, { useRef, useState } from 'react';
import AccountModal from '../../../components/AccountModal';
import WaitingModal from '../../../components/WaitingModal';
import config from '../../../dev/config';
import EntityItemsCollector from '../../../lib/EntityItemsCollector';
import ServerPath from '../../../lib/ServerPath';
import { Entity } from '../../../types';


const VerificationSettingsScreen: React.FC = () => {
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showWaitingModal, setWaitingModal] = useState(false);
    const entity = useRef({} as Entity);


    const handleAccountSelect = (account: string): void => {
        setWaitingModal(true);
        collectEntityInfo(config.blockchain.url, account, config.claim.context, account)
        .then(resp => {
            entity.current = resp;
        })
        .catch(error => {
            entity.current = {} as Entity;
        })
        .finally(() => {
            setShowAccountModal(true);
            setWaitingModal(false);
        })
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Verification
                    </IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonImg
                        className="toolbar-img"
                        slot="end"
                        style={{height: "30px"}}
                        src={ServerPath.get("/assets/common/blobaa-ico.png")}/>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItemGroup>
                        <IonItemDivider>
                            <IonLabel color="dark">
                                <h2>
                                    <b>Trusted Entities</b>
                                </h2>
                            </IonLabel>
                        </IonItemDivider>
                        <IonItem button onClick={() => handleAccountSelect(config.verification.rootAccount)}>
                            <IonLabel>
                                <h2>
                                    Account: {config.verification.rootAccount}
                                </h2>
                                <p>
                                    <IonText color="primary">
                                        Context: {config.verification.context}
                                    </IonText>
                                </p>
                            </IonLabel>
                        </IonItem>
                        <IonItemDivider>
                            <IonLabel color="dark">
                                <h2>
                                    <b>Signature Timeout </b>
                                </h2>
                            </IonLabel>
                        </IonItemDivider>
                        <IonItem>
                            <IonLabel>
                                <h2>
                                    Timeout:
                                </h2>
                                <p>
                                    {config.verification.signatureTimeout} sec.
                                </p>
                            </IonLabel>
                        </IonItem>
                    </IonItemGroup>
                </IonList>
            </IonContent>
            {showWaitingModal ? <WaitingModal/>
            : null}
            {showAccountModal ? <AccountModal
                entity={entity.current}
                onCancel={() => setShowAccountModal(false)}/>
            : null}
        </IonPage>
    );
};


const collectEntityInfo = async (url: string, account: string, attestationContext: string, attestor: string): Promise<Entity> => {
    const entityResponse = await entity.getEntity(url, { account, attestationContext, attestor });
    const items = await new EntityItemsCollector(url, entityResponse.payload).collect();
    return {authItems: items, params: entityResponse};
}

export default VerificationSettingsScreen;
